import { Controller } from "stimulus"

export default class extends Controller {
  toggleStatus() {
    const all = ['', 'planning', 'progressing', 'done']

    // Allow to remove tick from checked checkbox
    let status
    if (this.data.get('done') === 'true') {
      status = all[all.indexOf(this.data.get('status')) - 1]
    } else {
      status = this.data.get('status')
    }

    this._update({
      activity: {
        status: status
      }
    })
  }

  toggleOnTrack() {
    const ontrack = this.data.get('ontrack') === 'true'

    return this._update({
      activity: {
        ontrack: !ontrack
      }
    })
  }

  _update(body) {
    const url = this.data.get('url')

    // Toggle optimistically to give immediate feedback
    this._toggleChecked()

    return Rails.fetch(url, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json'
      },
      body: JSON.stringify(body)
    })
      .then(response => response.json())
      .then(json => json.redirectTo)
      .then(url => {
        const locationParams = new URL(location.href).search
        return Rails.fetch(url + locationParams).then(response => {
          response.text().then(html => {
            const logbookHtml = new DOMParser().parseFromString(html, 'text/html').querySelector('.logbook-content').innerHTML
            document.querySelector('.logbook-content').innerHTML = logbookHtml
          })
        })
      })
      .catch((err) => {
        console.error(err)
        this._toggleChecked()
      })
  }

  _toggleChecked() {
    this.element.classList.toggle('done')
  }
}

// Mimick rails-ujs
const Rails = {
  csrfToken() {
    const meta = document.querySelector('meta[name=csrf-token]')
    if (meta) {
      return meta.getAttribute('content')
    }
  },

  // Native `fetch()` with Rails defaults
  fetch(url, options) {
    options = (options || {})
    options.headers = Object.assign({
      'X-Requested-With': 'XMLHttpRequest',
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    }, options.headers)

    return fetch(url, options)
  }
}
