import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.element.addEventListener('click', function(evt) {
      if(evt.target.tagName === 'A') {
         window.open(evt.target.href, '_blank')
      }
    })
  }
}
