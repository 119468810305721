// For provides (e.g. jQuery) refer to config/webpack/environment.js

// Unobtrusive scripting adapter
require('@rails/ujs').start()

// Direct uploads, see https://github.com/rails/rails/tree/master/activestorage#direct-upload-installation
require('@rails/activestorage').start()

// Turbolinks, see https://github.com/turbolinks/turbolinks
require('turbolinks').start()

// Bootstrap and related plugins
require('bootstrap')
require('data-confirm-modal')

dataConfirmModal.setDefaults({
  title: 'Sicher?',
  commit: 'Weiter',
  cancel: 'Abbrechen',
  commitClass: 'btn-primary'
});

// Rich-text editing
require("trix")
require("@rails/actiontext")

// Stimulus, see https://github.com/hotwired/stimulus/blob/v1.1.1/docs/handbook/
import 'controllers'
