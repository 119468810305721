import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["header"]

  connect() {
    this.observeStickyHeader()
  }

  disconnect() {
    this.unobserveStickyHeader()
  }

  // See https://davidwalsh.name/detect-sticky
  observeStickyHeader() {
    this.headerObserver = new IntersectionObserver(
      ([e]) => e.target.classList.toggle('sticky', e.intersectionRatio < 1),
      { threshold: [1] }
    )

    this.headerObserver.observe(this.headerTarget)
  }

  unobserveStickyHeader() {
    this.headerObserver.unobserve(this.headerTarget)
  }
}
